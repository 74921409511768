import { select, put, take, fork, cancel, takeEvery } from "redux-saga/effects";
import { selectors } from "reducers";
import { listenCallEvents } from "./event-handler";
import { handleCallConnection } from "./connection-handler";
import { Types } from "actions/phone";

import * as Sentry from "@sentry/react";

function* handleIncomingCall(device, { payload: { twilioCall } }) {
  try {
    const autoAnswerIncoming = twilioCall?.customParameters?.get("auto_answer");
    const from = twilioCall?.customParameters?.get("From")?.replace("+1", "");

    // task to watch accept or reject call
    const callTask = yield fork(listenCallEvents, twilioCall, device);
    const callActions = yield fork(handleCallConnection, twilioCall, device);

    if (autoAnswerIncoming) {
      twilioCall.accept();
      yield put({ type: Types.TWILIO_EVENT_ACCEPT_INCOMING });
    }

    yield put({ type: Types.SET_CALL_DIRECTION, payload: "inbound" });
    yield put({ type: Types.SET_CONNECTED_INCOMING_NUMBER, payload: from });

    yield take(Types.TWILIO_EVENT_DISCONNECT);

    yield cancel(callActions);
    yield cancel(callTask);
  } catch (error) {
    console.error(error);
    Sentry.withScope(scope => {
      scope.setTag("location", window.location.href);
      scope.setTag("incomingCall", true);
      Sentry.captureException(error);
    });
  }
}

export default function* watchIncomingCall(device) {
  yield takeEvery(Types.TWILIO_EVENT_INCOMING, handleIncomingCall, device);
}
